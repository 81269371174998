import React from 'react'
import "./Footer.scss"
import { useDispatch, useSelector } from 'react-redux'

export const Footer = () => {

  const langState = useSelector((state) => state.lang.lang)
  const dispatch = useDispatch()

  return (
    <footer className='footer'>
      <div className="footer-top">
        <p className='footer-top-mail'>info@salmastrent.com</p>
        <p className='footer-top-phone'>099-999-999</p>
        <p className='footer-top-name'>Salmast Rentals</p>
      </div>
      <div className="footer-bottom">
        <p>{langState==="hy"?"2024թ. Վեբ կայքը մշակվել և ծրագրավորվել է":langState==="ru"?"В 2024 году. Веб-сайт был разработан и запрограммирован":"In 2024. The website was designed and programmed by a"}
          <a href="https://globalitacademy.am/">Global It</a>
          <span>{langState==="hy"?"ընկերության կողմից":langState==="ru"?"- компанией.":"company."}</span>
        </p>
      </div>
    </footer>
  )
}
